import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//Custom directives
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

// You can also pass in the default options
Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

  let scrollpos = window.scrollY
  const header = document.querySelector("header")
  const logo = document.querySelector(".imgLogo")
  const header_height = header.offsetHeight

  
  const add_logo_class_on_scroll = () => logo.classList.add("logoScroll")
  const add_logo_class_on_scroll2 = () => logo.classList.add("imgLogo")

  window.addEventListener('scroll', function() { 
    scrollpos = window.scrollY;

    if (scrollpos >= header_height) { 
     
      add_logo_class_on_scroll();
    }
    else { 
      add_logo_class_on_scroll2();
      logo.classList.remove("logoScroll")
    } 

    console.log(scrollpos)
  })

  const menu_mobile = document.querySelector(".mobile_menu").childNodes;
  const check = document.querySelector("#check");


  
  for (let index = 0; index < menu_mobile.length; index++) {
    const element = menu_mobile[index];
    element.addEventListener('click',function(){
      check.checked = false;
  } )
}

