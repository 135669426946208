<template>
  <div>
    
    <!-- built files will be auto injected -->
    <div class="destacado">

      <div class="flexslider">
          <ul class="slides">
            <li>
              <router-link to="/productos">
                <img src="assets/images/grupo.png" />
              </router-link>
            </li>

            <li>
              <router-link to="/productos">
                <img src="assets/images/slider/hilaza-poliester.jpg" />
              </router-link>
            </li>

            <li>
              <router-link to="/productos">
                <img src="assets/images/slider/pimatex-hilaza-nylon.jpg" />
              </router-link>
             </li>

             <li>
              <router-link to="/productos">
                <img src="assets/images/slider/pimatex-hilazas.jpg" />
              </router-link>
             </li>

             <li>
              <router-link to="/productos">
                <img src="assets/images/slider/pimatex-hilo-poliester-grande.jpg" />
              </router-link>
              </li>

              <li>
              <router-link to="/productos">
                <img src="assets/images/slider/pimatex-hilo-poliester.jpg" />
              </router-link>
            </li>
            
            <li>
              <router-link to="/productos">
                <img src="assets/images/slider/pimatex-hilos.jpg" />
              </router-link>
            </li>
            
            
          </ul>
        </div>


      
      
  
      <div class="elementos_destacados">
          <div class="elemento">
              <h2>Hilaza Poliester <div class="img_destacado2"><img src="assets/images/enka@2x.png" alt=""></div></h2><br>
              <p>La hilaza poliéster la manejamos en 1 cabo 150/1 y de 2 cabos 150/2, en estos dos tipos de fibras ofrecemos diferentes tamaños, 200 gr, 300 gr, 500gr, por kilogramos, y tortas de 4 kilogramos aproximadamente, nos diferenciamos por la calidad de hilaza que ofrecemos al igual que la extensa gama de colores en nuestro stock.</p>
          </div>
          <div class="elemento">
              <h2>Hilaza Nylon <div class="img_destacado2"><img src="assets/images/hilaza-nylon-pimatex.png" alt=""></div></h2><br>
              <p>La hilaza nylon de referencia 100/24/2 es una fibra especial para confeccionar prendas elásticas, como, Fajas, licras, ropa interior, vestidos de baño, ropa deportiva y cualquier otro tipo de prenda que requiera de elasticidad, ya que este material aporta a los tejidos propiedades como, suavidad, elasticidad, alto volumen, resistencia a la abrasión, y excelente comportamiento al teñido, como sus principales características; Con esta hilaza su prenda va elongar lo necesario, sin reventarse y le brindara una excelente calidad a su producto. </p>
          </div>
      </div>

      
  </div>


  


<main>
  
  
  <section id="quienes_somos" class="container_quienes_somos">
    <img src="assets/images/almacen2.jpg" alt="pimatex" class="img_articulo"> 
      <h2 class="flex"><img src="assets/images/simbolo.svg" alt="pimatex"> VISIÓN</h2>
      <div class="articulo_quienes_somos">
          <article class="quienes_somos">
              <p>
                  Somos una empresa colombiana dedicada a la distribución de insumos textiles para la confección, desde hace de 25 años.
              </p><br>
              <p>Distribuimos hilos, hilazas poliéster e hilazas nylon, estos como nuestros productos más fuertes; Nos enfocamos en suplir las necesidades de cada uno de nuestros clientes por esto en Insumos Pimatex SAS hacemos hincapié en tener una excelente calidad en cada uno de nuestros productos, al igual que una extensa gama de colores para así satisfacer a cada uno de nuestros consumidores que se encuentran a nivel local y nacional.</p>
              <br>
              <h2 class="flex"><img src="assets/images/simbolo.svg" alt="pimatex"> QUIENES SOMOS</h2>
              <p>Nuestra misión es brindarles a nuestros clientes un excelente servicio, aportando calidad a sus prendas y una amplia variedad de colores en todos nuestros productos, con precios bastante competitivos y por supuesto una óptima calidad, de esta forma crecer como empresa de la mano de nuestros apreciados clientes.</p>
              <br>
              <h2 class="flex"><img src="assets/images/simbolo.svg" alt="pimatex">MISIÓN</h2>
              Aspiramos a ser una empresa reconocida a nivel nacional por brindarle a la industria materias primas de calidad con gran variedad, buen servicio y competitividad en precios. 
          </article>
          <img src="assets/images/pimatex-2.jpg" alt="pimatex" class="img_articulo">  
      </div>
      
  </section>

  <section id="ubicacion" class="container_quienes_somos">
     <h2 class="flex"><img src="assets/images/simbolo.svg" alt="pimatex"> ¿DONDE NOS UBICAMOS?</h2>
      
          <article class="quienes_somos">
              <p>
                  Estamos localizados en pleno corazón de Bogotá en el barrio la Alquería, de la Localidad de Puente Aranda, visitanos y te brindaremos los productos de mejor calidad y mejores precios del mercado <br><br>
              </p>
              
          </article>
          
          <iframe class="ubicacion" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31816.07307286004!2d-74.12933859207774!3d4.59238298647033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9f215e22e211%3A0xe3a3e6a142d94843!2sInsumos%20pimatex!5e0!3m2!1sen!2sco!4v1593660837233!5m2!1sen!2sco" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
     
      
  </section>


  <section id="contactenos" class="container_quienes_somos">
      <h2 class="flex"><img src="assets/images/simbolo.svg" alt="pimatex">CONTACTENOS</h2>
      <div class="contactenos">
          <article class="quienes_somos">
              <p>
                  ¿Tienes alguna pregunta?, No dudes en escribirnos o llamarnos a los siguientes teléfonos  7 41 54 62 - 300 218 65 89 - 300 218 66 16, al Whatsapp +57 300 2 186 589 o visitarnos en nuestra dirección Insumos Pimatex Calle 42 # 53-17 sur. Bogotá - Colombia o enviarnos un email a <a href="insumos.pimatex@hotmail.com ">insumos.pimatex@hotmail.com</a> o <a href="mailto:info@pimatex.com.co">info@pimatex.com.co</a> 
              </p>
              <p>
                Encuentranos en Facebook como <a href="https://www.facebook.com/Insumos-Pimatex-SAS-130252541905944" target="blank">insumos Pimatex SAS </a> el Instagram <a href="https://www.instagram.com/insumospimatexsas" target="blank">insumospimatexsas</a>
                <ul class="redes">
        <a href="https://www.facebook.com/Insumos-Pimatex-SAS-130252541905944" target="blank"><li class="zocial-facebook"></li></a>
        <a href="https://www.instagram.com/insumospimatexsas" target="blank"><li class="zocial-instagram"></li></a>
      </ul>
              </p>

              <img src="assets/images/pimatex.jpg" alt="pimatex" class="img_articulo">
              
          </article>
          <!-- <form action="" method="post">
              <input type="text" name="" id="">
              <input type="text" name="" id="">
              <input type="number" name="" id="">
              <input type="email" name="" id="">
              <textarea name="" id="" cols="30" rows="10"></textarea>
              <button type="submit">Enviar</button>
          </form>  -->
      </div>
      
  </section>
  </main>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
